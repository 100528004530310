import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { AccountCouponForm } from "../../../components/PageSegments/subscription/AccountCouponForm";
import { AppLogo } from "../../../components/ui";
import { BasicLayout } from "../../../layouts/BasicLayout";
import { COUPON_DISCOUNT_DATA } from "../../../util/variables";
// import { discount_flow_appear } from "../../../services/mixpanel/discount-flow-events";
import { Content, FlexContainer, LogoContainer, Title } from "./index";
import { useOffer } from "../../../hooks/useOffer";

export default (data: any) => {
  const { search, state } = data.location;
  const urlParams = new URLSearchParams(search);
  const couponParam = state?.couponId ?? urlParams.get("cid");
  const flowParam = urlParams.get("from");
  // currently used to determine if the user is a new user or a returning paid user
  // new_user === free trial; else no free trial
  const typeParam = urlParams.get("type");
  const hasFreeTrial = typeParam === "new_user";
  const priceIdParam = state?.priceId ?? urlParams.get("pid");
  const { priceData } = useOffer(data.location, "A");

  useEffect(() => {
    if (!!couponParam && !COUPON_DISCOUNT_DATA.find(item => item.id === couponParam)) {
      navigate("/");
    }

    if (typeParam) {
      localStorage.setItem("flow", `discount - ${typeParam.replace("_", " ")}`);
    } else if (flowParam) {
      localStorage.setItem("flow", `discount - ${flowParam}`);
    } else {
      localStorage.setItem("flow", `discount - support`);
    }

    // discount_flow_appear();
  }, [couponParam, flowParam, typeParam]);

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <LogoContainer>
          <AppLogo size="medium" />
        </LogoContainer>
        <Content>
          <Title>
            {hasFreeTrial ? "Start Your 7-Day Free Trial" : "Unlock the Future of Learning"}
          </Title>
          <AccountCouponForm cid={couponParam ?? ""} priceId={priceIdParam ?? priceData.price_id} trial={hasFreeTrial} />
        </Content>
      </FlexContainer>
    </BasicLayout>
  );
};
